/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import {
  ClassNameOverrides,
  CleanedRTF,
} from "@bond-london/graphcms-rich-text";

export interface QAINFO {
  id: string;
  title?: object;
  headline?: string;
  Quote?: string;
  name?: string;
  workPosition?: string;
  image?: string;
  position?: string;
  loaction?: string;
  cleanedMessage?: CleanedRTF;
  className?: string;
  rtfClassNameOverrides?: ClassNameOverrides;
  externalVideoUrl?: string;
  externalVideoTitle?: string;
  rtfClassName?: string;
  QAPart?: object;
}
const tmp = 0;
export const GA_imageQuote: React.FC<PropsWithChildren<QAINFO>> = ({
  title,
  headline,
  cleanedMessage,
  Quote,
  position,
  image,
  className,
  name,
  workPosition,
  loaction,
  QAPart,
}) => {
  let realRTF;
  if (cleanedMessage) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    realRTF = cleanedMessage;
  }

  let leftClassName: string;
  let rightClassName: string;

  if (!title && !headline) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (headline) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }
  if (position == "LEFT") {
    return (
      <section
        data-component="Statement"
        className={classNames("ga-ptb-30 page-grid max-width z-0 bg-white-1")}
      >
        <div className="col-span-12 col-start-2 md:col-start-2 md:col-span-6">
          <img src={image} className="ga-image-quotes-person-image" />
        </div>
        <div className="col-span-12 md:col-span-6 col-start-2  ga-image-quotes-person-image ga-padding-40">
          {title && (
            <h4
              className={classNames(
                leftClassName,
                "lg:row-span-2 p1 ga-paragraph-title ga-font-style-s24",
                className
              )}
            >
              {title}
            </h4>
          )}
          {Quote && (
            <h5
              className={classNames(
                leftClassName,
                "mt-20px lg:mt-0 text-right-margin p3  ga-font-style-s16"
              )}
            >
              {Quote}
            </h5>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section
        data-component="ga_image_carousel"
        className={classNames(" ga-ptb-30 page-grid max-width z-0 bg-white-1")}
      >
        <div
          className={classNames(
            "col-span-12 col-start-2 md:col-start-2 md:col-span-6  ga-image-quotes-person-image ga-padding-40 ",
            className
          )}
        >
          {title && (
            <h4
              className={classNames(
                leftClassName,
                "lg:row-span-2 p1 ga-paragraph-title ga-font-style-s24",
                className
              )}
            >
              {title}
            </h4>
          )}
          {Quote && (
            <p
              className={classNames(
                leftClassName,
                "mt-20px lg:mt-0 text-right-margin p2  ga-font-style-s16"
              )}
            >
              {Quote}
            </p>
          )}
          <br />
          <div>
            {name && (
              <p
                className={classNames(
                  leftClassName,
                  "mt-20px lg:mt-0 text-right-margin p2  ga-font-style-s16 ga-details"
                )}
              >
                <b>{name}</b>
              </p>
            )}
            {workPosition && (
              <p
                className={classNames(
                  leftClassName,
                  "mt-20px lg:mt-0 text-right-margin p2  ga-font-style-s16 ga-details"
                )}
              >
                {workPosition}
              </p>
            )}
            {loaction && (
              <p
                className={classNames(
                  leftClassName,
                  "mt-20px lg:mt-0 text-right-margin p2  ga-font-style-s16 ga-details"
                )}
              >
                {loaction}
              </p>
            )}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 col-start-2  ">
          <img src={image} className="ga-image-quotes-person-image" />
        </div>
        {QAPart && (
          <div className="col-span-12 md:col-span-12 col-start-2 md:col-start-2  ga-mt-20">
            {QAPart.map((n) => (
              <div className="col-span-12 md:col-span-12 w-full">
                <p
                  className={classNames(
                    leftClassName,
                    "p3 ga-mb-20",
                    className
                  )}
                >
                  <b>{n.label}</b>
                </p>
                <p
                  className={classNames(
                    leftClassName,
                    "p3 space-y-20px pb-48px p3 text-right-margin "
                  )}
                >
                  {n.message}
                </p>
              </div>
            ))}
          </div>
        )}
      </section>
    );
  }
};
