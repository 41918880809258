/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import {
  ClassNameOverrides,
  CleanedRTF,
} from "@bond-london/graphcms-rich-text";
import { Researchblock } from "./DownloadBlock";
import leftIcon from "../images/Left Button.svg";

export interface QAINFO {
  id: string;
  title1?: string;
  title2?: string;
  Link1?: string;
  Link2?: string;
  headline?: string;
  Quote?: string;
  name?: string;
  image?: string;
  position?: string;
  loaction?: string;
  cleanedMessage?: CleanedRTF;
  className?: string;
  rtfClassNameOverrides?: ClassNameOverrides;
  externalVideoUrl?: string;
  externalVideoTitle?: string;
  rtfClassName?: string;
}

export const GA_CTA: React.FC<PropsWithChildren<QAINFO>> = ({
  title1,
  title2,
  Link1,
  Link2,
  headline,
}) => {
  // let realRTF;
  // if (cleanedMessage) {
  // realRTF = cleanedMessage;
  // }
  // else if (message) {
  //   realRTF = getCleanedRTF(message);
  // }

  // if (!title && !headline && (!message || !cleanedMessage)) {
  //   return null;
  // }

  let leftClassName: string;
  let rightClassName: string;

  if (!title1 && !headline) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (headline) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }
  return (
    <>
      <section className="py-section page-grid bg-white-1">
        <a
          href="/en/our-social-values"
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Our social values
        </a>
      </section>
      <section
        data-component="GA_CTA"
        className={classNames(
          "py-section page-grid max-width z-0 bg-light-blue ga-padding-15"
        )}
      >
        <div className="col-span-12 md:col-start-2 md:col-span-5  col-start-2 ga-firstblock">
          <Researchblock quote="" Title={title1} Link={Link1} CTA="CTA" />
        </div>
        <div className="vl col-span-2 self-center pl-2/3"></div>
        <div className="col-span-12 md:col-span-5 col-start-2 ga-secondblock ">
          <Researchblock quote="" Title={title2} Link={Link2} CTA="CTA" />
        </div>
      </section>
    </>
  );
};
