/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import { Hero, Statement } from "../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../layouts";
import { rtfFromText } from "@bond-london/graphcms-rich-text";
import { GA_paragraph } from "../components/GA_paragrph";
import { GA_imageQuote } from "../components/GA_imageQuote";
import { GA_CTA } from "../components/GA_CTA";
import Bona from "../images/Bona.png";
import phailantropy from "../images/phailantropy.png";
import supporting from "../images/supporting.png";
import socialHead3 from "../images/social-page-3.png";

// const component: React.FC<PageProps<Pick<Query, "graphCmsSectorPage">>> = ({
//   path,
//   data: { graphCmsSectorPage: sectorPage },
// }) => {
//   console.log(sectorPage);
//   if (!sectorPage) return null;
//   return (
//     <Layout
//       pagePath={path}
//       menu={buildMenu(sectorPage.menu)}
//       footerMenu={buildMenu(sectorPage.footerMenu)}
//       keywords={sectorPage.keywords}
//       title={sectorPage.pageTitle || sectorPage.sector}
//       description={sectorPage.description}
//     >
//       <Hero
//         title="Partners in Performance Energy Transition Insights"
//         video={getVideo(sectorPage.heroVideo)}
//         image={getImage(sectorPage.heroImage)}
//         short={true}
//         treatment={sectorPage.heroTreatment}
//         showSearch={true}
//       />
//       <Statement
//         title=""
//         headline="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
//         id={""}
//         className="custom-class"
//       />
//       <ResearchComponent name="" />
//     </Layout>
//   );
// };

// export default component;

// export const sectorPageQuery = graphql`
//   query sectorPageQueryAndSectorPageQuery($id: String) {
//     graphCmsSectorPage(id: { eq: $id }) {
//       sectorType
//       pageTitle
//       keywords
//       description
//       menu {
//         ...MenuFragment
//       }
//       businessChallengesIntroduction
//       businessChallenges {
//         headline
//         id
//         content {
//           cleaned
//         }
//       }
//       heroImage {
//         ...ImageFragment
//       }
//       statement {
//         cleaned
//       }
//       heroVideo {
//         ...VideoFragment
//       }
//       heroTreatment
//       id
//       keyStatistic
//       leadership {
//         ...ProfileFragment
//       }
//       leadershipTitle
//       quote
//       quoteFrom {
//         ...ProfileFragment
//       }
//       infographicIntroduction {
//         cleaned
//       }
//       infographicDetails {
//         cleaned
//       }
//       genericInfographicText {
//         cleaned
//       }
//       sector
//       slug
//       statisticDetails
//       storiesTitle
//       storiesImage {
//         ...ImageFragment
//       }
//       successes {
//         __typename
//         ... on GraphCMS_CaseStudy {
//           ...CaseStudyReferenceFragment
//         }
//         ... on GraphCMS_Story {
//           ...StoryReferenceFragment
//         }
//         ... on GraphCMS_Insight {
//           ...InsightReferenceFragment
//         }
//       }
//       form {
//         ...FormFragment
//       }
//     }
//   }
// `;

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    sizes: "(min-width: 1280px) 1280px, 100vw",
    src: socialHead3,
    srcSet: socialHead3,
    sources: [],
    layout: "constrained",
    width: 1280,
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={sectorPage?.pageTitle || sectorPage?.sector}
      description={sectorPage?.description}
    >
      <Hero
        title="Make a difference by giving back"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We believe unleashing our individual potential starts when people feel empowered to bring their entire selves to work."
        headline=""
        cleanedMessage={rtfFromText(
          "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        )}
        id={""}
        className=""
      />
      <GA_imageQuote
        id={""}
        title="Pro Bono Work"
        Quote="Our pro bono efforts aim to build partnerships with a set of non-profit organisations that are aligned to our core values, helping unleash their full potential and have even greater impact in the communities they serve."
        position="LEFT"
        image={Bona}
        className=""
      />
      <GA_paragraph
        id={""}
        title=""
        messagez="Our commitment (finalise once agreed)
        option for people to be allowed a few hours a week for pro bono in agreement with their engagement / line mgr (non billable to client)
        some firms grant community days each year as alternative
        Optional surcharge for pro bono on our pricing (e.g. salesforce adds 1% which people can decline)
        Support our probono clients by giving back during group gatherings (training, networking events)
        Promote organisations across our digital channels to increase awareness
        
        
        Our partnerships
        NACCHO (our indigenous cause –  naccho.org.au
        Schools Plus – helps disadvantage schools to close the gap of disadvantage for young children.(we had Rosemary Conn from SchoolsPlus talking ANZ team about the work we've been doing with them)
        RSCPA 
        Other work globally????
        "
        className="ga-paragraph-title"
      />
      <GA_imageQuote
        id={""}
        title="Philantropy and Charity"
        Quote="We have committed globally to a societal goal of supporting disadvantaged members of society both in actively working to increase access to renewable energy and/or assisting in upgrading infrastructure to improve sustainability, but also where our support can help bring positive change to the regions in which we live and work."
        position="RIGHT"
        image={phailantropy}
        className=""
      />
      <GA_paragraph
        id={""}
        title=""
        messagez="Our commitment (finalise once agreed)
        option for people to be allowed a few hours a week for pro bono in agreement with their engagement / line mgr (non billable to client)
        some firms grant community days each year as alternative
        Optional surcharge for pro bono on our pricing (e.g. salesforce adds 1% which people can decline)
        Support our probono clients by giving back during group gatherings (training, networking events)
        Promote organisations across our digital channels to increase awareness
        
        
        Our partnerships
        NACCHO (our indigenous cause –  naccho.org.au
        Schools Plus – helps disadvantage schools to close the gap of disadvantage for young children.(we had Rosemary Conn from SchoolsPlus talking ANZ team about the work we've been doing with them)
        RSCPA 
        Other work globally????
        "
        className="ga-paragraph-title"
      />
      <GA_imageQuote
        id={""}
        title="Supporting Personal Activism "
        Quote="We celebrate our people’s personal philanthropic and charitable involvement. We believe in using our voice and our platform to help bring attention to causes and organisations that do good in the world and are close to the hearts of our people."
        position="LEFT"
        image={supporting}
        className=""
      />
      <GA_paragraph
        id={""}
        title=""
        messagez="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        className="ga-paragraph-title"
      />
      <GA_CTA
        id={""}
        title1="Giving Back"
        title2="Climate Action"
        Link1=""
        Link2=""
      />
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
